import { Address } from "types/customer";
import { ProductDetailProps } from "assets/Content";
import { Timestamp } from "firebase/firestore";
type State = { id: string; qty: number };
type Discount = { amount: number; isPercentage: boolean };

const roundToTwoDecimal = (decimal: number) => Math.round(decimal * 100) / 100;
const getUnitPrice = (id: string, products: ProductDetailProps[]) =>
  products.find((product) => product.id.toString() == id)?.price ?? 0;

export const getSubtotal = (
  items: State[],
  products: ProductDetailProps[],
  discount: Discount,
) => {
  const subtotal = items.reduce((accum, current) => {
    const unitPrice = getUnitPrice(current?.id, products);
    const discountedUnitPrice = discount.isPercentage
      ? (unitPrice * (100 - discount.amount)) / 100
      : unitPrice;
    return discountedUnitPrice * current.qty + accum;
  }, 0);
  return roundToTwoDecimal(subtotal);
};

export const getTax = (
  items: State[],
  products: ProductDetailProps[],
  discount: Discount,
) => {
  const totalTax: number = items.reduce((accum, current) => {
    const unitPrice = getUnitPrice(current.id, products);
    const discountedUnitPrice = discount.isPercentage
      ? (unitPrice * (100 - discount.amount)) / 100
      : unitPrice;
    const tax =
      (Math.round((0.19 * discountedUnitPrice * 100) / 1.19) / 100) *
      current.qty;
    return tax + accum;
  }, 0);
  return roundToTwoDecimal(totalTax);
};

//  Math.round(getSubtotal(items) * 0.19 * 100) / 100;

export const getShipping = (
  items: State[],
  products: ProductDetailProps[],
  discount: Discount,
  shippingCountry?: any,
) => {
  if (shippingCountry?.minFreeShipping) {
    return getSubtotal(items, products, discount) <
      shippingCountry?.minFreeShipping
      ? shippingCountry?.shippingCost
      : 0;
  } else {
    return shippingCountry?.shippingCost ?? 6.5;
  }
};

export const getTotal = (
  items: State[],
  products: ProductDetailProps[],
  discount: Discount,
  shippingCountry?: any,
) =>
  Math.round(
    (getShipping(items, products, discount, shippingCountry) +
      getSubtotal(items, products, discount) -
      (discount.isPercentage ? 0 : discount.amount)) *
      100,
  ) / 100;

export const isValidEmail = (email: string) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    String(email).toLowerCase(),
  );

export const isValidPhoneNumber = (phoneNumber: string) =>
  /^[0-9]*$/?.test(String(phoneNumber)) && phoneNumber.length > 7;

export const isValidAddress = (address: any) => {
  //address.address_line_1
  return true;
};

export const isValidPostalCode = (postalCode: string) =>
  String(postalCode).match(/^[0-9]*$/) && postalCode.length == 5;

export const isValidLetters = (name: string) =>
  name
    .split("")
    .every((substr) => String(substr).match(/^[A-zÀ-ú|가-힣|\s]+$/)) &&
  name.length > 0;

export const getPerLiterPrice = (
  price: string | number | undefined,
  vol: string | number | undefined,
) => {
  const PerLiterPrice = Number(price) / (Number(vol) / 1000);
  return `${PerLiterPrice.toFixed(2).toString().replace(".", ",")}\u20AC/l`;
};

export const isOnlyNumbers = (onlyNumber: string) =>
  /^[0-9]*$/.test(onlyNumber);

export const removeSpecialCharacters = (text: string) =>
  text.replace(/\D/g, "");

export const formatHeader = (key: string) => {
  // Changes camel key to header format
  return key
    .replace(/([A-Z])/g, " $1") // Add space before uppercase letters
    .replace(/^./, (str: string) => str.toUpperCase()); // Capitalize first letter
};

export const calculateColumnWidths = (headers: string[], data: any[]) => {
  return headers.map((header, index) => {
    const maxLength = Math.max(
      header.length,
      ...data.map(
        (row) => (row[Object.keys(row)[index]] || "").toString().length,
      ),
    );
    return { width: maxLength + 2 }; // Add padding
  });
};

export const getFullAddress = (address: Address, person?: string) => {
  const fullAddress = [
    address?.address_line_1,
    address?.address_line_2,
    address?.admin_area_1,
    address?.admin_area_2,
    address?.postal_code,
    address?.country_code,
  ]
    .filter((e) => e)
    .join(", ");
  if (person) return [person, fullAddress].join(", ");
  else return fullAddress;
};


export const getDateString = (dateTimestamp: Timestamp) => {
      if (!dateTimestamp) return "";
      const date = dateTimestamp?.toDate().toISOString().split("T")[0];
      return date.replaceAll("-", "/");
    };